<template>
  <div v-if="!loading && mocktest && mocktest.id" class="question">
    <v-app-bar app>
      <v-btn icon @click.stop="$router.go(-1)">
        <v-icon>mdi-close</v-icon>
      </v-btn>
      <v-toolbar-title>{{ mocktest.title }}</v-toolbar-title>

      <v-spacer></v-spacer>
      <span class="bar-vertical"></span>
      <display-timer
        @finished="doSubmit(true)"
        :start="timerStart"
        :startTime="startTime"
        :duration="mocktest.duration"
        @tick="(_elapsed) => (elapsed = _elapsed)"
      />

      <v-app-bar-nav-icon @click.stop="drawer = !drawer"></v-app-bar-nav-icon>

      <template v-if="mocktest.segments.length > 1" v-slot:extension>
        <v-tabs v-model="segmentTab" class="mb-2">
          <v-tabs-slider color="primary"></v-tabs-slider>
          <v-tab
            @click="questionTab = 0"
            v-for="segment in mocktest.segments"
            :key="segment.id"
          >
            {{ segment.title }}
          </v-tab>
        </v-tabs>
      </template>
    </v-app-bar>

    <v-row class="text-center justify-center">
      <v-col cols="10" md="11">
        <v-tabs
          show-arrows
          center-active
          grow
          v-model="questionTab"
          class="mb-1"
        >
          <v-tabs-slider color="primary"></v-tabs-slider>
          <v-tab
            v-for="(question, idx) in mocktest.segments[segmentTab].questions"
            :key="question.id"
          >
            <div v-if="question && question.id">
              <v-icon :color="getBadge(question).color" small>
                {{ getBadge(question).icon }}
              </v-icon>
              {{ idx + 1 }}
            </div>
          </v-tab>
        </v-tabs>
      </v-col>
      <v-col cols="2" md="1" class="pt-5">
        <translate-exam-button />
      </v-col>
    </v-row>

    <v-tabs-items touchless v-model="segmentTab">
      <v-tab-item v-for="segment in mocktest.segments" :key="segment.id">
        <v-tabs-items
          touchless
          v-touch="{
            left: () => swipeLeft(),
            right: () => swipeRight(),
          }"
          v-model="questionTab"
        >
          <v-tab-item
            v-for="(question, idx) in segment.questions"
            :key="question.id"
          >
            <question-card
              :question="question"
              :idx="idx"
              :currentQuestionId="currentQuestion ? currentQuestion.id : null"
            />
          </v-tab-item>
          <!-- end question -->
        </v-tabs-items>
      </v-tab-item>
    </v-tabs-items>

    <mocktest-attempt-card-actions @next="goNext()" :item="currentQuestion" />

    <v-navigation-drawer v-model="drawer" app right>
      <v-list nav dense>
        <v-list-item>
          <v-list-item-title>{{ mocktest.title }}</v-list-item-title>
        </v-list-item>
        <v-divider></v-divider>
        <v-list-item @click="dialogConfirmSubmit = true" class="green">
          <v-icon class="mr-2">mdi-send</v-icon>
          {{ $lang("SUBMIT") + " " + $lang("TEST") }}
        </v-list-item>
      </v-list>
      <v-expansion-panels v-model="drawerPanel" multiple>
        <v-expansion-panel
          v-for="(segment, sidx) in mocktest.segments"
          :key="sidx"
        >
          <v-expansion-panel-header>
            {{ segment.title }}
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <div style="margin: 0 -1rem">
              <v-chip
                small
                label
                :class="'ma-1 ' + getBadge(qtn).color"
                v-for="(qtn, idx) in segment.questions"
                :key="idx"
                @click="
                  drawer = false;
                  segmentTab = sidx;
                  questionTab = idx;
                "
              >
                <v-icon :class="getBadge(qtn).color" x-small>{{
                  getBadge(qtn).icon
                }}</v-icon>
                {{ idx + 1 }}
              </v-chip>
            </div>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-navigation-drawer>
    <attempt-confirm-submit
      @clicked="handleSubmit"
      :show="dialogConfirmSubmit"
      :mocktest="mocktest"
    />
  </div>
  <div v-else>
    <v-card>
      <v-card-title>
        <v-spacer></v-spacer>
        {{ $lang("LOADING") }}
        <v-spacer></v-spacer>
      </v-card-title>
      <v-progress-linear
        color="orange accent-4"
        indeterminate
        rounded
        height="6"
      ></v-progress-linear>
    </v-card>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import bus from "@/bus";
import MocktestAttemptCardActions from "./MocktestAttemptCardActions";
import DisplayTimer from "@/components/DisplayTimer";
import QuestionCard from "@/components/QuestionCard";
import { axios } from "@/plugins/axios";
import AttemptConfirmSubmit from "../modules/AttemptConfirmSubmit";
import TranslateExamButton from "../modules/TranslateExamButton";
export default {
  name: "exams-mocktest",
  components: {
    MocktestAttemptCardActions,
    DisplayTimer,
    QuestionCard,
    AttemptConfirmSubmit,
    TranslateExamButton,
  },
  data() {
    return {
      item: {},
      segmentTab: 0,
      questionTab: 0,
      loading: true,
      drawer: null,
      drawerPanel: [],
      dialogConfirmSubmit: false,
      elapsed: 0,
      startTime: 0,
      timerStart: false,
    };
  },
  computed: {
    currentSegment() {
      return this.mocktest.segments[this.segmentTab];
    },
    currentQuestions() {
      return this.currentSegment.questions;
    },
    currentQuestion() {
      return this.currentQuestions[this.questionTab];
    },
    ...mapGetters({
      auth: "auth/auth",
      getMocktestsById: "app/exams/getMocktestsById",
      mocktests: "app/exams/mocktests",
      attempt: "app/exams/attempt",
    }),
    mocktest() {
      return this.mocktests.find((el) => el.id == this.$route.params.id);
      // return this.getMocktestsById(this.$route.params.id);
    },
  },
  watch: {
    elapsed(val) {
      this.mocktest.elapsed = val;
    },
    mocktest() {
      if (!this.mocktest || this.mocktest.id) {
        return;
      }
      this.expandQuestionPanels();
      this.getElapsed();
    },
    questionTab(val) {},
  },
  methods: {
    expandQuestionPanels() {
      this.drawerPanel = [...Array(this.mocktest.segments).keys()].map(
        (k, i) => i
      );
    },
    getBadge(qtn) {
      let color = " ";
      let icon = "";
      if (!qtn.review && qtn.user_option) {
        color = "primary white--text";
        icon = "mdi-check-circle";
      } else if (qtn.review && !qtn.user_option) {
        color = "orange white--text";
        icon = "mdi-star";
      } else if (qtn.review && qtn.user_option) {
        color = "orange white--text";
        icon = "mdi-star-check";
      } else {
        color = "";
        icon = "";
      }
      return {
        color,
        icon,
      };
    },
    swipeRight() {
      this.goPrevious();
    },
    swipeLeft() {
      this.goNext();
    },
    goNext() {
      this.persistMocktest();
      let segments = this.mocktest.segments;
      let questions = segments[this.segmentTab].questions;
      if (this.questionTab < questions.length - 1) {
        // can go next question
        this.questionTab++;
      } else {
        // cant go next question
        if (this.segmentTab < segments.length - 1) {
          // can go next segment
          this.segmentTab++;
          this.questionTab = 0;
        } else {
          // cant go next segment
          // this.segmentTab = 0;
          //this.questionTab = 0;
        }
      }
    },
    goPrevious() {
      this.persistMocktest();
      let segments = this.mocktest.segments;
      if (this.questionTab > 0) {
        // can go previous question
        this.questionTab--;
      } else {
        // cant go previous question
        if (this.segmentTab > 0) {
          // can go previous segment
          this.segmentTab--;
          let questions = segments[this.segmentTab].questions;
          this.questionTab = questions.length - 1;
        } else {
          // cant go previous segment
          // this.segmentTab = segments.length - 1;
        }
      }
    },
    getElapsed() {
      if (this.mocktest && this.mocktest.id) {
        if (!this.mocktest.elapsed) {
          this.mocktest.elapsed = 0;
        }
        this.elapsed = this.mocktest.elapsed;
        this.startTime = this.elapsed;
      }

      this.timerStart = true;
    },
    axiosMocktest() {
      return axios
        .get(
          `prep/mocktests/public/${this.$route.params.id}/questions?shuffle=1`
        )
        .then((res) => {
          this.$store.dispatch("app/exams/UpdateMocktests", res.data.data);
        })
        .catch((err) => {
          this.$router.go(-1);
        })
        .finally(() => {
          bus.$emit("hideAppBar");
          bus.$emit("hideBottomNav");
        });
    },
    persistMocktest() {
      this.$store.dispatch("app/exams/UpdateMocktests", this.mocktest);
    },
    handleUnload() {
      this.persistMocktest();
    },
    handleSubmit(submit) {
      this.dialogConfirmSubmit = false;
      if (!submit) return;

      this.doSubmit();
    },
    doSubmit(withConfirm = false) {
      // if (!withConfirm) {
      //   if (
      //     !confirm(`WAIT!!!!
      //     Once you submit this test,
      //     you cant attempt it again.
      //     Are you sure to submit the test?`)
      //   ) {
      //     return Promise.reject("CANCELED_BY_USER");
      //   }

      //   if (!confirm(`Really? Ready to submit?`)) {
      //     return Promise.reject("CANCELED_BY_USER");
      //   }
      // }

      bus.$emit("showWait");
      return axios
        .post("prep/attempts/submit", {
          data: this.mocktest,
          prep_mocktest_id: this.mocktest.id,
        })
        .then((res) => {
          if (this.mocktest && this.mocktest.id) {
            this.$store.dispatch("app/exams/RemoveMocktests", this.mocktest.id);
          }
          return this.saveAttemptRedirect(res);
        })
        .finally(() => {
          bus.$emit("hideWait");
        });
    },
    saveAttemptRedirect(res) {
      return this.$store
        .dispatch("app/exams/SaveAttempt", res.data.data)
        .then(() => {
          this.$router.replace({
            name: "exams-attempt-result",
            params: { id: this.$route.params.id },
          });
        });
    },
  },
  created() {
    bus.$emit("showWait");

    axios
      .get(`prep/attempts/attempted/${this.$route.params.id}`)
      .then((res) => {
        return this.saveAttemptRedirect(res);
      })
      .catch((err) => {
        // this test have not been attempted yet
        setTimeout(() => {
          if (this.mocktest && this.mocktest.id) {
            bus.$emit("hideAppBar");
            bus.$emit("hideBottomNav");
            return;
          }
          this.axiosMocktest();
        }, 500);
      })
      .finally(() => {
        bus.$emit("hideWait");
        this.loading = false;
      });
  },
  mounted() {
    window.addEventListener("beforeunload", this.handleUnload);
    setTimeout(() => {
      this.getElapsed();
      this.expandQuestionPanels();
    }, 1000);
  },
  destroyed() {
    bus.$emit("showBottomNav");
    bus.$emit("showAppBar");
    this.persistMocktest();
  },
};
</script>

<style>
/* #mocktest img {
  max-width: 100%;
} */
/* 
.question table {
  border-collapse: collapse;
  width: 100%;
  text-align: center;
}

.question td,
.question th {
  border: 1px solid;
  padding: 8px;
}

.question th {
  padding-top: 12px;
  padding-bottom: 12px;
  text-align: left;
  background-color: #4caf50;
  color: white;
}
.question p {
  padding: 0;
  margin: 0;
}
 */

.bar-vertical {
  content: "";
  height: 50%;
  width: 4px;
  background-color: orange;
  margin: 0 1rem;
}
</style>