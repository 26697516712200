var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-bottom-navigation',{attrs:{"grow":"","app":""}},[_c('v-btn',{attrs:{"disabled":!_vm.item.user_option,"color":"primary"},on:{"click":function () {
        _vm.review = false;
        _vm.item.review = false;
        _vm.$emit('next');
      }}},[_c('span',[_vm._v(_vm._s(_vm.$lang("SAVE")))]),_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-send")])],1),_c('v-btn',{attrs:{"color":_vm.review ? 'orange' : ''},on:{"click":function () {
        _vm.review = !_vm.review;
        _vm.item.review = _vm.review;
        if (_vm.review) { _vm.$emit('next'); }
      }}},[_c('span',[_vm._v(_vm._s(_vm.$lang(_vm.review ? "UNMARK" : "MARKANDNEXT")))]),_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-check")])],1),_c('v-btn',{attrs:{"disabled":!_vm.item.user_option,"color":"red"},on:{"click":function($event){_vm.item.user_option = null}}},[_c('span',[_vm._v(_vm._s(_vm.$lang("Clear")))]),_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-autorenew")])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }